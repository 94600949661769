// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-category-js": () => import("./../src/templates/blogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-page-test-js": () => import("./../src/templates/blogPageTest.js" /* webpackChunkName: "component---src-templates-blog-page-test-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

